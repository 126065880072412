import React, { useEffect, useState } from 'react';
import {
    CalculatorDiv,
    CardFlex,
    LeasCardsWrapper,
    LeasPackageWrapper,
    LeaseCalculatorWrappper,
    Tabs,
    Tab,
    TabSection,
} from './LeaseCalculatorStyled';
import { Col, Image, Row } from 'react-bootstrap';
import { PrimaryButton } from '../PrimaryButton';
import { Dropdown } from '../FormFields/Dropdown';
import { RangeSlider } from '../FormFields/RangeSlider';
import { LeaseCard } from '../LeaseCard/LeaseCard';
import { Meter } from '../Meter/Meter';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { navigate } from '@reach/router';
import { connect } from 'react-redux';
import {
    stateOptions,
    preSelectVariants,
    // evOptions,
    evCategory,
    pluginHVCategory,
    HatchbacksOptions,
    SedansOptions,
    CompactSUVOptions,
    MdLgSUVOptions,
    SUVHatchbacksOptions,
    SUVMediumOptions,
    SUVLarge
} from '../../utils/helpers';
import {
    getMakes,
    getModels,
    getModelYears,
    getBodyType,
    getVariants,
    getVariant,
    getQuote,
} from '../../actions/VehicleActions';
import { Formik, Form } from 'formik';
import { DropdownWrap } from '../FormFields/Dropdown/DropdownStyled';
import { OutlineButton } from '../OutlineButton';
import { PackageCard } from '../PackageCard';
import { Link } from 'theme-ui';

const LeaseCalculatorContainer = ({
    getMakes,
    makesOptions,
    getModels,
    modelOptions,
    getModelYears,
    getBodyType,
    modelYearOptions,
    bodyTypeOptions,
    getVariants,
    variantOptions,
    getVariant,
    getQuote,
    list_price_gross,
    saving_per_year,
    cost_per_fortnight,
    photo_url,
    quote,
    fortnight_car_cost,
    fortnight_running_cost,
    isElectric,
    shareOfSavings,
    perFortnight,
    isLoading,
    quoteError,
}) => {
    const currentURL = window.location.pathname;
    const pattern = /\/ev\/?$/;

    const isEv = pattern.test(currentURL);
    const [showCar, setShowCar] = useState(false);
    const [usedCar, setUsedCar] = useState(false);
    const [hybridCars, setHybridCars] = useState(false);
    const [activeTab, setActiveTab] = useState('detailed');
    const fuel_flag = isElectric ? 'no' : 'yes';
    const slug = window.location.pathname.substring(1);

    useEffect(() => {
        if (!isEv) {
            getMakes();
        }
    }, [isEv, getMakes]);

    const responsive = {
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };


    const initialValues = {
        vehicle_make: "",
        vehicle_model: "",
        vehicle_size: "",
        year: "",
        body_type: "",
        variants: "184085",
        salary: 90000,
        travelDistance: 15000,
        price_range: 40000,
        leaseTerm: 5,
        state: "VIC",
        category: "4",
      };

      useEffect(()=>{
        if(isEv){
          getVariant(initialValues.variants, initialValues, null, slug);
        } // eslint-disable-next-line
      },[])

    const HybridOptions = (values) => {
        switch (values) {
            case '1':
                 return  SUVHatchbacksOptions;
            case '2':
                return  SUVMediumOptions;
            case '3':
                return  SUVLarge;
            default:
                return SUVHatchbacksOptions;
        }
    };

    const EVOptions = (values) => {
        switch (values) {
            case '1':
                 return  HatchbacksOptions;
            case '2':
                return  SedansOptions;
            case '3':
                return  CompactSUVOptions;
            case '4':
                return  MdLgSUVOptions;
            default:
                return HatchbacksOptions;
        }
    };


    return (
        <LeaseCalculatorWrappper>
            <Formik enableReinitialize initialValues={initialValues}>
                {(props) => {
                    const { values, setFieldValue } = props;

                    return (
                        <Form
                            onKeyDown={(e) => {
                                if ((e.charCode || e.keyCode) === 13) {
                                    e.preventDefault();
                                }
                            }}
                        >
                            <section className="Lease-section">
                                <Image
                                    className="big-cloud"
                                    alt="icon"
                                    src="/Images/Vector.png"
                                />
                                <Image
                                    className="half-cloud"
                                    alt="icons"
                                    src="/Images/Vector2.png"
                                />
                                {isEv ? (
                                    <>
                                        <Image
                                            className="lightning-icon1"
                                            alt="icon"
                                            width="55"
                                            height="95"
                                            src={`/Images/${
                                                isEv
                                                    ? 'Lightning1'
                                                    : 'Group'
                                            }.png`}
                                        />
                                        <Image
                                            className="lightning-icon2"
                                            alt="icons"
                                            width="44"
                                            height="80"
                                            src={`/Images/${
                                                isEv
                                                    ? 'Lightning2'
                                                    : 'Group'
                                            }.png`}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Image
                                            className="dollar-icon1"
                                            alt="icon"
                                            width="54"
                                            height="54"
                                            src="/Images/Group.png"
                                        />
                                        <Image
                                            className="dollar-icon2"
                                            alt="icons"
                                            width="54"
                                            height="54"
                                            src="/Images/Group.png"
                                        />
                                    </>
                                )}

                                <Image
                                    className="small-cloud"
                                    alt="icons"
                                    src="/Images/Vector3.png"
                                />
                                <Row>
                                    <Col
                                        className="text-left pb"
                                        sm={8}
                                        md={7}
                                        lg={7}
                                    >
                                        <CalculatorDiv>
                                            <p className="Lease-paragraph">
                                                Finance your new car with
                                                Easi
                                            </p>
                                            <h1 className="lease-heading">
                                                {isEv
                                                    ? 'Electric Vehicle Novated Savings Calculator'
                                                    : 'Novated Lease Savings Calculator'}
                                            </h1>
                                            <p className="looking-for">
                                                What are you looking for?
                                            </p>

                                            {!isEv &&
                                            <OutlineButton
                                                handleOnclick={() => {
                                                    setUsedCar(false);
                                                }}
                                                className="mr-3"
                                                btnText={
                                                   'New Vehicle'
                                                }
                                                type="button"
                                                isActive={!usedCar}
                                                round
                                                sm
                                            />}

                                            {!isEv && (
                                                <OutlineButton
                                                    handleOnclick={() => {
                                                        setUsedCar(true);
                                                        setActiveTab(
                                                            'basic'
                                                        );
                                                    }}
                                                    className="mr-3"
                                                    isActive={usedCar}
                                                    type="button"
                                                    btnText="Used Vehicle"
                                                    round
                                                    sm
                                                />
                                            )}
                                            {!isEv && (
                                                <OutlineButton
                                                    handleOnclick={() => {
                                                        navigate('/ev');
                                                    }}
                                                    type="button"
                                                    btnText="Electric Vehicle"
                                                    round
                                                    sm
                                                />
                                            )}
                                            {isEv && (
                                                <>
                                                    <OutlineButton
                                                        handleOnclick={() => {
                                                            setHybridCars(
                                                                false
                                                            );
                                                        }}
                                                        type="button"
                                                        btnText="Electric Vehicles"
                                                        round
                                                        isActive={
                                                            !hybridCars
                                                        }
                                                        sm
                                                    />
                                                    <OutlineButton
                                                        handleOnclick={() => {
                                                            setHybridCars(
                                                                !hybridCars
                                                            );
                                                        }}
                                                        className="ml-3"
                                                        type="button"
                                                        isActive={
                                                            hybridCars
                                                        }
                                                        btnText="Plug-In Hybrid Vehicles"
                                                        round
                                                        sm
                                                    />
                                                </>
                                            )}

                                            <p className="cant-find">
                                                Can't find the car you
                                                like? Contact us on{' '}
                                                <Link href="tel:1300266828">
                                                    1300 266 828
                                                </Link>
                                                .
                                            </p>
                                            <div className="Vehicle-size">
                                                {showCar && (
                                                    <Image
                                                        className="mb-4"
                                                        alt=""
                                                        src={
                                                            photo_url
                                                                ? photo_url
                                                                : '/Images/placeholder.png'
                                                        }
                                                    />
                                                )}
                                                <Tabs>
                                                    {!usedCar && (
                                                        <Tab
                                                            isActive={
                                                                activeTab ===
                                                                'detailed'
                                                            }
                                                            onClick={() =>
                                                                setActiveTab(
                                                                    'detailed'
                                                                )
                                                            }
                                                        >
                                                            Get Detailed
                                                            Estimate
                                                        </Tab>
                                                    )}
                                                    {!isEv && (
                                                        <Tab
                                                            isActive={
                                                                activeTab ===
                                                                'basic'
                                                            }
                                                            preActive={
                                                                usedCar
                                                            }
                                                            onClick={() =>
                                                                setActiveTab(
                                                                    'basic'
                                                                )
                                                            }
                                                        >
                                                            Get A Basic
                                                            Estimate
                                                        </Tab>
                                                    )}
                                                </Tabs>
                                                <TabSection className="row">
                                                    {activeTab ===
                                                    'basic' ? (
                                                        <>
                                                            <Col md={12}>
                                                                <Dropdown
                                                                    label="Vehicle Size"
                                                                    options={
                                                                        preSelectVariants
                                                                    }
                                                                    name="variants"
                                                                    innerLable
                                                                    fullWidth
                                                                    className="p-0"
                                                                    customHandle={(
                                                                        val
                                                                    ) => {
                                                                        setShowCar(
                                                                            false
                                                                        );
                                                                        getVariant(
                                                                            val,
                                                                            values,
                                                                            activeTab ===
                                                                                'basic'
                                                                                ? values.price_range
                                                                                : list_price_gross,
                                                                            slug
                                                                        );
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col md={12}>
                                                                <RangeSlider
                                                                    min={
                                                                        15000
                                                                    }
                                                                    headtext={
                                                                        'How much did you want to spend?'
                                                                    }
                                                                    className="p-0"
                                                                    current={
                                                                        values.price_range
                                                                    }
                                                                    step={
                                                                        1000
                                                                    }
                                                                    max={
                                                                        150000
                                                                    }
                                                                    before={
                                                                        '$'
                                                                    }
                                                                    name="price_range"
                                                                    handleFinalChange={(
                                                                        price,
                                                                        annual_salary = values.salary,
                                                                        lease_period = values.leaseTerm,
                                                                        variant_id = values.variants,
                                                                        annual_kilometres = values.travelDistance,
                                                                        state = values.state
                                                                    ) =>
                                                                        variant_id &&
                                                                        getQuote(
                                                                            lease_period,
                                                                            variant_id,
                                                                            price,
                                                                            annual_kilometres,
                                                                            annual_salary,
                                                                            state,
                                                                            fuel_flag,
                                                                            slug,
                                                                            isEv
                                                                        )
                                                                    }
                                                                />
                                                            </Col>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {!isEv ? (
                                                                <>
                                                                    <Col
                                                                        md={
                                                                            6
                                                                        }
                                                                    >
                                                                        <Dropdown
                                                                            label="Vehicle Make"
                                                                            options={
                                                                                makesOptions
                                                                            }
                                                                            name="vehicle_make"
                                                                            innerLable
                                                                            fullWidth
                                                                            customHandle={(
                                                                                val
                                                                            ) => {
                                                                                getModels(
                                                                                    val
                                                                                );
                                                                                setFieldValue(
                                                                                    'vehicle_model',
                                                                                    ''
                                                                                );
                                                                                setFieldValue(
                                                                                    'year',
                                                                                    ''
                                                                                );
                                                                                setFieldValue(
                                                                                    'body_type',
                                                                                    ''
                                                                                );
                                                                                setFieldValue(
                                                                                    'variants',
                                                                                    ''
                                                                                );
                                                                            }}
                                                                            value={
                                                                                values.vehicle_make
                                                                            }
                                                                        />
                                                                    </Col>
                                                                    <Col
                                                                        md={
                                                                            6
                                                                        }
                                                                    >
                                                                        <Dropdown
                                                                            label="Vehicle Model"
                                                                            options={
                                                                                modelOptions
                                                                            }
                                                                            name="vehicle_model"
                                                                            innerLable
                                                                            fullWidth
                                                                            customHandle={(
                                                                                val
                                                                            ) => {
                                                                                getModelYears(
                                                                                    val
                                                                                );
                                                                                setFieldValue(
                                                                                    'year',
                                                                                    ''
                                                                                );
                                                                                setFieldValue(
                                                                                    'body_type',
                                                                                    ''
                                                                                );
                                                                                setFieldValue(
                                                                                    'variants',
                                                                                    ''
                                                                                );
                                                                            }}
                                                                            noOptionsMessage={
                                                                                isLoading
                                                                                    ? 'Loading...'
                                                                                    : 'Please select vehicle model'
                                                                            }
                                                                            value={
                                                                                values.vehicle_model
                                                                            }
                                                                        />
                                                                    </Col>
                                                                    <Col
                                                                        md={
                                                                            6
                                                                        }
                                                                    >
                                                                        <Dropdown
                                                                            label="Model Year"
                                                                            options={
                                                                                modelYearOptions
                                                                            }
                                                                            name="year"
                                                                            innerLable
                                                                            fullWidth
                                                                            customHandle={(
                                                                                model_year,
                                                                                vehicle_model = values.vehicle_model
                                                                            ) => {
                                                                                getBodyType(
                                                                                    model_year,
                                                                                    vehicle_model
                                                                                );
                                                                                setFieldValue(
                                                                                    'body_type',
                                                                                    ''
                                                                                );
                                                                                setFieldValue(
                                                                                    'variants',
                                                                                    ''
                                                                                );
                                                                            }}
                                                                            noOptionsMessage={
                                                                                isLoading
                                                                                    ? 'Loading...'
                                                                                    : 'Please select model year'
                                                                            }
                                                                            value={
                                                                                values.year
                                                                            }
                                                                        />
                                                                    </Col>
                                                                    <Col
                                                                        md={
                                                                            6
                                                                        }
                                                                    >
                                                                        <Dropdown
                                                                            label="Body Type"
                                                                            options={
                                                                                bodyTypeOptions
                                                                            }
                                                                            name="body_type"
                                                                            innerLable
                                                                            fullWidth
                                                                            customHandle={(
                                                                                body_type,
                                                                                model_year = values.year,
                                                                                vehicle_model = values.vehicle_model
                                                                            ) => {
                                                                                getVariants(
                                                                                    body_type,
                                                                                    model_year,
                                                                                    vehicle_model
                                                                                );
                                                                                setFieldValue(
                                                                                    'variants',
                                                                                    ''
                                                                                );
                                                                            }}
                                                                            noOptionsMessage={
                                                                                isLoading
                                                                                    ? 'Loading...'
                                                                                    : 'Please select body type'
                                                                            }
                                                                            value={
                                                                                values.body_type
                                                                            }
                                                                        />
                                                                    </Col>
                                                                    <Col
                                                                        md={
                                                                            12
                                                                        }
                                                                    >
                                                                        <Dropdown
                                                                            label="Variant"
                                                                            options={
                                                                                variantOptions
                                                                            }
                                                                            name="variants"
                                                                            innerLable
                                                                            fullWidth
                                                                            className="p-0"
                                                                            customHandle={(
                                                                                val
                                                                            ) => {
                                                                                setShowCar(
                                                                                    true
                                                                                );
                                                                                getVariant(
                                                                                    val,
                                                                                    values,
                                                                                    null,
                                                                                    slug
                                                                                );
                                                                            }}
                                                                            noOptionsMessage={
                                                                                isLoading
                                                                                    ? 'Loading...'
                                                                                    : 'Please select variant'
                                                                            }
                                                                            value={
                                                                                values.variants
                                                                            }
                                                                        />
                                                                    </Col>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {hybridCars ? (
                                                                        <>
                                                                            <Col
                                                                                md={
                                                                                    12
                                                                                }
                                                                            >
                                                                                 <Dropdown
                                                                                    label="Category"
                                                                                    options={
                                                                                        pluginHVCategory
                                                                                    }
                                                                                    name="category"
                                                                                    innerLable
                                                                                    fullWidth
                                                                                    className="p-0 mb-4"
                                                                                    value={
                                                                                        values.category
                                                                                    }

                                                                                />
                                                                                <Dropdown
                                                                                    label="Plug-In Hybrid Vehicles"
                                                                                    options={
                                                                                        HybridOptions(values.category)
                                                                                    }
                                                                                    name="variants"
                                                                                    innerLable
                                                                                    fullWidth
                                                                                    className="p-0"
                                                                                    value={
                                                                                        values.variants
                                                                                    }
                                                                                    customHandle={(
                                                                                        variant_id
                                                                                    ) =>
                                                                                        getVariant(
                                                                                            variant_id,
                                                                                            values,
                                                                                            null,
                                                                                            slug,
                                                                                            isEv
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <Col
                                                                                md={
                                                                                    12
                                                                                }
                                                                            >
                                                                                 <Dropdown
                                                                                    label="Category"
                                                                                    options={
                                                                                        evCategory
                                                                                    }
                                                                                    name="category"
                                                                                    innerLable
                                                                                    fullWidth
                                                                                    className="p-0 mb-4"
                                                                                    value={
                                                                                        values.category
                                                                                    }

                                                                                />
                                                                                <Dropdown
                                                                                    label="Electric Vehicles"
                                                                                    options={
                                                                                        EVOptions(values.category)
                                                                                    }
                                                                                    name="variants"
                                                                                    innerLable
                                                                                    fullWidth
                                                                                    className="p-0"
                                                                                    value={
                                                                                        values.variants
                                                                                    }
                                                                                    customHandle={(
                                                                                        variant_id
                                                                                    ) =>
                                                                                        getVariant(
                                                                                            variant_id,
                                                                                            values,
                                                                                            null,
                                                                                            slug,
                                                                                            isEv
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                        </>
                                                                    )}

                                                                    {/*
                                                                    // Previous Options
                                                                    <Col
                                                                        md={
                                                                            12
                                                                        }
                                                                    >
                                                                        <Dropdown
                                                                            label="Electric Vehicles"
                                                                            options={
                                                                                evOptions
                                                                            }
                                                                            name="variants"
                                                                            innerLable
                                                                            fullWidth
                                                                            className="p-0"
                                                                            value={
                                                                                values.variants
                                                                            }
                                                                            customHandle={(
                                                                                variant_id,
                                                                            ) =>
                                                                                getVariant(
                                                                                    variant_id,
                                                                                    values,
                                                                                    null,
                                                                                    slug,
                                                                                    isEv
                                                                                )
                                                                            }
                                                                        />
                                                                    </Col> */}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </TabSection>
                                            </div>
                                            <DropdownWrap>
                                                <p>
                                                    What state do you live
                                                    in?
                                                </p>
                                                <Dropdown
                                                    label="select your state"
                                                    options={stateOptions}
                                                    name="state"
                                                    value={values.state}
                                                    innerLable
                                                    fullWidth
                                                />
                                            </DropdownWrap>
                                            <RangeSlider
                                                min={20000}
                                                headtext={
                                                    'What is your annual salary?'
                                                }
                                                current={values.salary}
                                                step={1000}
                                                max={200000}
                                                before={'$'}
                                                name="salary"
                                                overMax
                                                handleFinalChange={(
                                                    annual_salary,
                                                    lease_period = values.leaseTerm,
                                                    variant_id = values.variants,
                                                    approximate_price = activeTab ===
                                                    'basic'
                                                        ? values.price_range
                                                        : list_price_gross,
                                                    annual_kilometres = values.travelDistance,
                                                    state = values.state
                                                ) =>
                                                    variant_id &&
                                                    getQuote(
                                                        lease_period,
                                                        variant_id,
                                                        approximate_price,
                                                        annual_kilometres,
                                                        annual_salary,
                                                        state,
                                                        fuel_flag,
                                                        slug,
                                                        isEv
                                                    )
                                                }
                                            />
                                            <RangeSlider
                                                min={5000}
                                                headtext={
                                                    'How far will you travel in a year?'
                                                }
                                                current={
                                                    values.travelDistance
                                                }
                                                step={1000}
                                                max={40000}
                                                after={'km'}
                                                name="travelDistance"
                                                handleFinalChange={(
                                                    annual_kilometres,
                                                    lease_period = values.leaseTerm,
                                                    variant_id = values.variants,
                                                    approximate_price = activeTab ===
                                                    'basic'
                                                        ? values.price_range
                                                        : list_price_gross,
                                                    annual_salary = values.salary,
                                                    state = values.state
                                                ) =>
                                                    variant_id &&
                                                    getQuote(
                                                        lease_period,
                                                        variant_id,
                                                        approximate_price,
                                                        annual_kilometres,
                                                        annual_salary,
                                                        state,
                                                        fuel_flag,
                                                        slug,
                                                        isEv
                                                    )
                                                }
                                            />

                                            <RangeSlider
                                                min={1}
                                                headtext={
                                                    'Preferred length of lease term?'
                                                }
                                                current={values.leaseTerm}
                                                step={1}
                                                max={5}
                                                after={'Years'}
                                                name="leaseTerm"
                                                handleFinalChange={(
                                                    lease_period,
                                                    variant_id = values.variants,
                                                    approximate_price = activeTab ===
                                                    'basic'
                                                        ? values.price_range
                                                        : list_price_gross,
                                                    annual_kilometres = values.travelDistance,
                                                    annual_salary = values.salary,
                                                    state = values.state
                                                ) =>
                                                    variant_id &&
                                                    getQuote(
                                                        lease_period,
                                                        variant_id,
                                                        approximate_price,
                                                        annual_kilometres,
                                                        annual_salary,
                                                        state,
                                                        fuel_flag,
                                                        slug,
                                                        isEv
                                                    )
                                                }
                                            />
                                        </CalculatorDiv>
                                    </Col>

                                    <Col
                                        sm={8}
                                        md={5}
                                        lg={5}
                                        className="pb"
                                    >
                                        <div className={`counter`}>
                                            <Meter
                                                heading1={'Annual Savings'}
                                                text1={
                                                    'Total annual estimated savings'
                                                }
                                                heading2={
                                                    'Fortnightly Cost'
                                                }
                                                text2={
                                                    'Total estimated fortnightly cost'
                                                }
                                                annual={Math.round(
                                                    saving_per_year ?? 0
                                                )}
                                                fortnight={Math.round(
                                                    cost_per_fortnight ?? 0
                                                )}
                                                runningCost={
                                                    fortnight_running_cost
                                                }
                                                carCost={
                                                    fortnight_car_cost
                                                }
                                                shareOfSavings={
                                                    shareOfSavings
                                                }
                                                perFortnight={perFortnight}
                                                slug={slug}
                                            />

                                        {quoteError ? (
                                            <div class="quote-error">
                                                <p>
                                                    For a quote, please contact us at{' '}<Link href="mailto:info@easifleet.com.au">info@easifleet.com.au</Link> or call{' '}<Link href="tel:1300266828">1300 266 828</Link>.
                                                </p>
                                            </div>
                                        ) : null}

                                            {saving_per_year != null && (
                                                <>
                                                    <div className="Lease-button">
                                                        <PrimaryButton
                                                            type="button"
                                                            btnText="Get Discounted Quote"
                                                            md
                                                            handleOnclick={() =>
                                                                navigate(
                                                                    '/quote',
                                                                    {
                                                                        state: {
                                                                            leaseData:
                                                                                values,
                                                                            cost_per_fortnight,
                                                                            saving_per_year,
                                                                            fortnight_car_cost,
                                                                            fortnight_running_cost,
                                                                            quote_type:
                                                                                'discounted',
                                                                            car_type:
                                                                                usedCar
                                                                                    ? 'used car'
                                                                                    : 'new car',
                                                                            isEv: isEv,
                                                                        },
                                                                    }
                                                                )
                                                            }
                                                            fullWidth
                                                        />
                                                        <div className="email-button">
                                                            <Link
                                                                onClick={() =>
                                                                    navigate(
                                                                        '/quote',
                                                                        {
                                                                            state: {
                                                                                leaseData:
                                                                                    values,
                                                                                cost_per_fortnight,
                                                                                saving_per_year,
                                                                                fortnight_car_cost,
                                                                                fortnight_running_cost,
                                                                                quote_type:
                                                                                    'pre-approved',
                                                                                car_type:
                                                                                    usedCar
                                                                                        ? 'used car'
                                                                                        : 'new car',
                                                                                isEv: isEv,
                                                                            },
                                                                        }
                                                                    )
                                                                }
                                                                sx={{
                                                                    display:
                                                                        'block',
                                                                    color: '#000',
                                                                    fontSize:
                                                                        '14px',
                                                                    mt: 3,
                                                                    cursor: 'pointer',
                                                                    transition:
                                                                        'color 0.3s ease-in-out',
                                                                    '&:hover':
                                                                        {
                                                                            color: '#ef404a',
                                                                        },
                                                                }}
                                                            >
                                                                Get
                                                                Pre-Approved
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <LeasPackageWrapper>
                                                        <h2 className="Include">
                                                            What’s included
                                                            fortnightly?
                                                        </h2>
                                                        <CardFlex>
                                                            {!isEv && (
                                                                <PackageCard
                                                                    imgUrl="/Images/fuel.svg"
                                                                    label="Fuel Card"
                                                                />
                                                            )}
                                                            {isEv && (
                                                                <PackageCard
                                                                    imgUrl="/Images/electricity.svg"
                                                                    label="Electricity"
                                                                />
                                                            )}
                                                            <PackageCard
                                                                imgUrl="/Images/servicemaintenance.svg"
                                                                label="Maintenance"
                                                            />
                                                            <PackageCard
                                                                imgUrl="/Images/registration.svg"
                                                                label="Registration"
                                                            />
                                                            <PackageCard
                                                                imgUrl="/Images/tyres.svg"
                                                                label={
                                                                    'Tyres (' +
                                                                    quote?.t_notes +
                                                                    ')'
                                                                }
                                                            />
                                                            <PackageCard
                                                                imgUrl="/Images/roadsideassist.svg"
                                                                label="Roadside Assistance"
                                                            />
                                                            <PackageCard
                                                                imgUrl="/Images/comprehensiveinsurance.svg"
                                                                label="Car Insurance"
                                                            />
                                                            <PackageCard
                                                                imgUrl="/Images/management-outline.svg"
                                                                label="Admin fee"
                                                            />
                                                            <PackageCard
                                                                imgUrl="/Images/keys.png"
                                                                label="Lease Rental"
                                                            />
                                                        </CardFlex>
                                                    </LeasPackageWrapper>
                                                </>
                                            )}
                                        </div>
                                        <div className="d-none d-md-block car-icon">
                                            {isEv && (
                                                <Image
                                                    alt="icon"
                                                    width="350"
                                                    height="96"
                                                    src="/Images/Car.png"
                                                />
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col
                                        className="text-left mb-5 mb-md-0"
                                        sm={8}
                                        md={7}
                                        lg={7}
                                    >
                                        {quote && (
                                            <LeasCardsWrapper>
                                                <h2 className="Include d-block d-sm-none">
                                                    What’s included?
                                                </h2>
                                                <Carousel
                                                    responsive={responsive}
                                                    centerMode={true}
                                                    infinite={true}
                                                    removeArrowOnDeviceType={
                                                        'mobile'
                                                    }
                                                    itemClass="carousel-item-padding-bottom-40-px"
                                                    containerClass="set-items"
                                                >
                                                    {isEv ? (
                                                        <LeaseCard
                                                            imgUrl="/Images/electricity.svg"
                                                            price={
                                                                'Included'
                                                            }
                                                            label="Electricity"
                                                            className="mr-3"
                                                        />
                                                    ) : (
                                                        <LeaseCard
                                                            imgUrl="/Images/fuel.svg"
                                                            price={
                                                                'Included'
                                                            }
                                                            label="Fuel Card"
                                                            className="mr-3"
                                                        />
                                                    )}
                                                    <LeaseCard
                                                        imgUrl="/Images/servicemaintenance.svg"
                                                        price={'Included'}
                                                        label="Maintaince"
                                                        className="mr-3"
                                                    />
                                                    <LeaseCard
                                                        imgUrl="/Images/registration.svg"
                                                        price={'Included'}
                                                        label="Registration"
                                                        className="mr-3"
                                                    />
                                                    <LeaseCard
                                                        imgUrl="/Images/tyres.svg"
                                                        price={'Included'}
                                                        label={
                                                            'Tyres (' +
                                                            quote?.t_notes +
                                                            ')'
                                                        }
                                                        className="mr-3"
                                                    />
                                                    <LeaseCard
                                                        imgUrl="/Images/roadsideassist.svg"
                                                        price={'Included'}
                                                        label="Roadside Assistance"
                                                        className="mr-3"
                                                    />
                                                    <LeaseCard
                                                        imgUrl="/Images/comprehensiveinsurance.svg"
                                                        price={'Included'}
                                                        label="Car Insurance"
                                                        className="mr-3"
                                                    />
                                                    <LeaseCard
                                                        imgUrl="/Images/management-outline.svg"
                                                        price={'Included'}
                                                        label="Admin fee"
                                                        className="mr-3"
                                                    />
                                                    <LeaseCard
                                                        imgUrl="/Images/keys.png"
                                                        price={'Included'}
                                                        label="Lease Rental"
                                                        className="mr-3"
                                                    />
                                                </Carousel>
                                            </LeasCardsWrapper>
                                        )}
                                    </Col>
                                    <div className="d-block d-md-none car-icon">
                                        {isEv && (
                                            <Image
                                                alt="icon"
                                                width="314"
                                                height="86"
                                                src="/Images/Car.png"
                                            />
                                        )}
                                    </div>
                                </Row>
                            </section>
                        </Form>
                    );
                }}
            </Formik>
        </LeaseCalculatorWrappper>
    );
};
const mapStateToProps = (state) => {
    const quote = state.vehicle.quote;
    const quoteError = state.vehicle.quoteError;
    const variant = state.vehicle.variant;
    return {
        isCalculating: state.vehicle.isCalculating,
        isLoading: state.vehicle.isLoading,
        makesOptions: state.vehicle.make_options,
        modelOptions: state.vehicle.model_options,
        modelYearOptions: state.vehicle.model_years_options,
        bodyTypeOptions: state.vehicle.body_type_options,
        variantOptions: state.vehicle.variant_options,
        list_price_gross: variant.list_price_gross || 44381.0,
        saving_per_year: quote ? quote.advantage_per_year : null,
        cost_per_fortnight: quote
            ? parseInt(quote.after_tax_effect_per_pay_cycle)
            : null,
        fortnight_car_cost: quote
            ? parseInt(quote.after_tax_effect_per_pay_cycle) -
              (parseInt(quote.reg_per_pay_cycle_gross) +
                  parseInt(quote.maint_per_pay_cycle_gross) +
                  parseInt(quote.t_per_pay_cycle_gross) +
                  parseInt(quote.rsa_per_pay_cycle_gross) +
                  parseInt(quote.ins_per_pay_cycle_gross) +
                  parseInt(quote.f_per_pay_cycle_gross) +
                  parseInt(quote.mfee_per_pay_cycle_gross))
            : null,
        fortnight_running_cost: quote
            ? parseInt(quote.reg_per_pay_cycle_gross) +
              parseInt(quote.maint_per_pay_cycle_gross) +
              parseInt(quote.t_per_pay_cycle_gross) +
              parseInt(quote.rsa_per_pay_cycle_gross) +
              parseInt(quote.ins_per_pay_cycle_gross) +
              parseInt(quote.mfee_per_pay_cycle_gross) +
              parseInt(quote.f_per_pay_cycle_gross)
            : null,
        photo_url: variant ? variant.photo : null,
        isElectric: variant
            ? parseFloat(variant.engine_litres) === 0 &&
              parseFloat(variant.fuel_metro) === 0
            : false,
        quote: quote,
        quoteError: quoteError,
        shareOfSavings: quote?.share_of_savings,
        perFortnight: quote?.share_of_savings_per_pay_cycle,
    };
};

export const LeaseCalculator = connect(mapStateToProps, {
    getMakes,
    getModels,
    getModelYears,
    getBodyType,
    getVariants,
    getVariant,
    getQuote,
})(LeaseCalculatorContainer);
